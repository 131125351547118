export interface IColor {
  '50': string;
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
}

const colors = {
  primary: {
    '50': '#e0f1f2',
    '100': '#b3dde0',
    '200': '#80c6cb',
    '300': '#4dafb6',
    '400': '#269ea6',
    '500': '#008d96',
    '600': '#00858e',
    '700': '#007a83',
    '800': '#007079',
    '900': '#005d68',
  },
  secondary: {
    '50': '#fceeee',
    '100': '#f7d4d4',
    '200': '#f2b8b8',
    '300': '#ec9c9c',
    '400': '#e88686',
    '500': '#e47171',
    '600': '#e16969',
    '700': '#dd5e5e',
    '800': '#d95454',
    '900': '#d14242',
  },
  info: {
    50: '#e5e7f0',
    100: '#bec3d9',
    200: '#929cbf',
    300: '#6674a5',
    400: '#465692',
    500: '#25387f',
    600: '#213277',
    700: '#1b2b6c',
    800: '#162462',
    900: '#0d174f',
  },
  success: {
    50: '#eaf4f0',
    100: '#cbe4d9',
    200: '#a8d2bf',
    300: '#85bfa5',
    400: '#6ab292',
    500: '#50a47f',
    600: '#499c77',
    700: '#40926c',
    800: '#378962',
    900: '#27784f',
  },
  warning: {
    50: '#f9f2e9',
    100: '#f0dfc8',
    200: '#e6caa3',
    300: '#dcb47e',
    400: '#d5a463',
    500: '#cd9447',
    600: '#c88c40',
    700: '#c18137',
    800: '#ba772f',
    900: '#ae6520',
  },
  danger: {
    50: '#fee8e7',
    100: '#fcc7c3',
    200: '#faa19b',
    300: '#f77b72',
    400: '#f65f54',
    500: '#f44336',
    600: '#f33d30',
    700: '#f13429',
    800: '#ef2c22',
    900: '#ec1e16',
  },
  neutral: {
    50: '#fdfdfd',
    100: '#f2f2f2',
    200: '#ccc',
    300: '#b8b8b8',
    400: '#a8a8a8',
    500: '#999',
    600: '#8a8a8a',
    700: '#666',
    800: '#333',
    900: '#111',
  },
  black: '#000',
  white: '#fff',
};

export default colors;
